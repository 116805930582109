import { DefaultSession } from "next-auth";
import { getSession, signOut } from "next-auth/react";
import { redirect } from "next/navigation";

export interface IAuthSession {
    email: string,
    id: string,
    permissions: string,
    session_token: string,
}

/**
 * Module augmentation for `next-auth` types. Allows us to add custom properties to the `session`
 * object and keep type safety.
 *
 * @see https://next-auth.js.org/getting-started/typescript#module-augmentation
 */
declare module "next-auth" {
    interface Session extends DefaultSession {
        user: DefaultSession["user"] & IAuthSession
    }
}


export type IAuthFetch = (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>;


export const authFetch: IAuthFetch = async (input, init) => {
    const currentSession = await getSession();
    const injectedInit = { ...init };
    if (currentSession?.user) {
        const { session_token } = currentSession.user;
        if (init && init.headers) {
            const headers = new Headers(init.headers);
            headers.set("Authorization", `Bearer ${session_token}`);
            injectedInit.headers = headers;
        } else {
            injectedInit.headers = new Headers({ "authorization": `Bearer ${session_token}` });
        }

    } else {
        console.warn("No use session available!");
    }
    const response = await fetch(input, injectedInit);
    if (response.status === 403) {
        signOut();
        redirect("/login");
    }
    return response;
};