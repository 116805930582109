export default function AboutUs() {
    return (
        <div  className="about-container">
            <div className="about-desc">
                <div className="about-box">
                    <h1 className="about-header">About Us</h1>
                    <p className="about-text">
                    Our marketplace offers a wide range of templated AI solutions covering a variety of industries that will help users deliver high-quality products that reduce costs and increase operational efficiency within a business.
                    </p>
                    {/* <Link href='/main' className="bg-[#443BBC] rounded-2xl p-5 pl-7 text-white font-semibold leading-5 flex justify-between w-80">
                        Jump to Marketplace <img src="/images/landing/arrow-right-w.svg" />
                    </Link> */}
                </div>
                <div className="about-img">
                    <img 
                    className="about-img-style"
                    src="/images/landing/about.png" alt="About Us" />
                </div>
       </div>
     
            <div className="features-set">
                <div className="flex justify-start items-start flex-col flex-1 ">
                    <img src="/images/landing/flash.png" alt="Flash" />
                    <h2 className="text-[21px] font-semibold pb-1 pt-3">
                        Boosted Efficiency
                    </h2>
                    <p className="description">
                        AI streamlines tasks, enhancing productivity for smooth operations
                    </p>
                </div>
                <div className="flex justify-start items-start flex-col flex-1 ">
                    <img src="/images/landing/solution.png" alt="Solutions" />
                    <h2 className="text-[21px] font-semibold pb-1 pt-3">
                    Customized Solutions
                    </h2>
                    <p className="description">
                    Tailored AI tools for diverse industries ensure targeted benefits                    </p>
                </div>
                <div className="flex justify-start items-start flex-col  flex-1 ">
                    <img src="/images/landing/integration.png" alt="Integrations" />
                    <h2 className="text-[21px] font-semibold pb-1 pt-3">
                    Easy Integration
                    </h2>
                    <p className="description">
                    Intuitive AI seamlessly merges with existing systems, no tech skills needed                    </p>
                </div>
                <div className="flex justify-start items-start flex-col  flex-1 ">
                    <img src="/images/landing/innovation.png" alt="Flash" />
                    <h2 className="text-[21px] font-semibold pb-1 pt-3">
                    Ongoing Innovation                    </h2>
                    <p className="description">
                    Stay ahead with ever-expanding AI advancements for business growth                    </p>
                </div>

            </div>
        </div>
    );
}