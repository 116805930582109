import React from "react";
// import Rating from "./Rating";
import Link from "next/link";
import ProductTypeIcon from "./Products/ProductTypeIcon";
import { purchaseTemplate } from "~/api/templates";
import { useMutation } from "react-query";

export interface IProductCard {
    name: string;
    id: string;
    // rating: number;
    type: string;
    niche: string;
    credit: number;
}

export default function ProductCard(props: IProductCard) {
    const { name, type: productType, niche, credit, id } = props;
    const { mutate: purchaseHandler, isLoading: isPurchaseProcessing, error: purchaseError } = useMutation<unknown, Error | { message: string }, string>({
        mutationFn: purchaseTemplate,
        onError: (e) => {
            alert(e.message);
        }
    });

    const handleBuyNow = () => {
        if (id) {
            purchaseHandler(id as string);
        } else {
            throw new Error("Product ID is not available!");
        }
    };
    
    return (
        <div  className="max-w-[30rem] flex gap-y-5 flex-col justify-evenly items-start p-4 px-[1.5rem] shrink-0 rounded-[1.325rem] bg-[#F8FAFD] border border-[#F1F5FB] border-solid m-2">
            <div className="flex w-full justify-start items-center gap-x-2 pt-[1rem]">

                <img src="/images/landing/morningside.png" alt="Morningside" className="w-[2.6rem] h-[2.6rem]  object-cover" />
                <h3 className="text-[#020618] grow font-semibold text-[1.3rem] text-ellipsis overflow-hidden truncate">
                    {name}
                </h3>
            </div>
            <div className="flex gap-[4rem] pt-[.5rem]">
                <div className="flex flex-col">
                    <span className="text-[#94A5B9] text-xs leading-3">Type</span>
                    <div className="font-medium text-[#020618] capitalize flex justify-center items-center grow ">
                        {/* <ProductTypeIcon productType={prodType} /> */}
                        {productType}
                    </div>
                </div>
                <div className="flex flex-col">
                    <span className="text-[#94A5B9] text-xs leading-3">Niche</span>
                    <div className="font-medium text-[#020618] capitalize grow  flex-col justify-center items-center flex">
                        {niche.replaceAll("_", " ")}
                    </div>
                </div>
            </div>
            {/* <Rating rating={rating} /> */}
            <Link href={`/products/${id}`} className="bg-[#fff] hover:bg-gray-100 text-black w-full ml-[-2.5%] p-5 justify-center items-stretch border border-[#F1F5FB] rounded-2xl">
                    <span className="flex justify-center items-center text-lg"> Browse Product</span>  
            </Link>
        </div>
    );
}