import { QueryFunctionContext } from "react-query";
import { authFetch } from "~/utils/Auth";
import { API_BASE_PATH } from "~/utils/general";

export const getUserCreditBalance = async (context: QueryFunctionContext): Promise<{ credit_balance: number }> => {
    const apiEndpoint = `${API_BASE_PATH}/v1/users/balance`;
    const response = await authFetch(apiEndpoint);
    if (response.ok) {
        const data = await response.json();
        return data.payload;
    } else {
        throw new Error("Error while fetching the data");
    }
};

