import Link from "next/link";
import Image from "next/image";
import ProductCard from "../ProductCard";
import { useQuery } from "react-query";
import { getTemplates } from "~/api/templates";

export default function Explore() {
    const { isLoading, data, error } = useQuery(["templates", { query: "" }], getTemplates);
    
    return (
        <div className="explore">
            {/*
            <div className="flex flex-col grid-cols-3">
                <h1 className="text-[3.25rem] font-[500] pb-[1rem] font-semibold">
                    Explore New Technologies 
                </h1>
                <div className="gap-4 flex ">
                    <div className="w-1/3">
                        <div className="relative h-auto rounded-xl">
                        <Image src='/explore-image-one.png' alt='Product Image 1' width={1000} height={400} />
                        </div>
                        <div className="flex flex-col mt-2">
                            <div className="text-[#443BBC] text-sm">New</div>
                            <h2 className="text-3xl">
                                AI Scheduling App
                            </h2>
                        </div>
                    </div>
                    <div className="w-1/3">
                        <div className="relative h-auto">
                        <Image src='/explore-image-two.png' alt='Product Image 2' width={1000} height={400} />

                        </div>
                        <div className="flex flex-col mt-2">
                            <div className="text-[#443BBC] text-sm">New</div>
                            <h2 className="text-3xl">
                                Voiceflow to Slack Template
                            </h2>
                        </div>
                    </div>

                    <div className="w-1/3 text-center">
                        <div className="relative h-auto rounded-3xl hover:scale-105 transform transition-transform duration-200">
                            <Link href="/account">

                                <Image src='/upload_template.png' alt='Product Image 3' className='rounded-3xl' width={1000} height={400} />

                            </Link>
                        </div>
                        <div className="mt-2">
                            <p className='text-[#443BBC]'>Create your own example template</p>
                        </div>
                    </div>
                    
                </div>
            </div>
    */}
            <div className="discover-tech">
                {/* Discover section */}
                <h1 className="text-[3.25rem] font-[500] pb-[1rem] font-semibold">
                    Discover 
                </h1>
                <div className="flex gap-x pb-[2rem] overflow-x-auto custom-scrollbar">
                    {!isLoading && data && (
                        data.items.map((item: any) => (
                            <ProductCard id={item.id} key={item.id} name={item.title} type={item.template_type} niche={item.industry} credit={item.price}  />
                        ))
                    )}
                </div>
                <Link href='/main' className="discover-all-btn white-btn hover:bg-[#6348D6]">
                    Discover All
                </Link>
            </div>
        </div>
    );
}
