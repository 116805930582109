import Accordion from "../Accordion";

export default function FAQs() {
    return (
        <div className="faq-box">
            <h1 className="text-[3.25rem] font-[500] pb-[1rem] font-semibold">FAQs</h1>
            <Accordion title="What is the Automation Station?" >
            The Automation Station is a marketplace that allows your to download pre-designed templates built by qualified developers, helping users deliver high-quality products that reduce costs and increase operational efficiency within a business.            </Accordion>
            <Accordion title="How do I download a template?" >
            By accessing our marketplace and selecting a product, you will see the option to click a button that reads Install. 
            </Accordion>
            <Accordion title="Do I need technical expertise to use these templates?" >
            Yes, a foundational understanding of how to adjust and expand these solutions is necessary to customize them for your personal use or for the specific requirements of the businesses you are servicing.            </Accordion>
            <Accordion title="Who can access and download these templates?" >
            The platform is in beta development and is currently only available to AAA Accelerator members.             </Accordion>
            <Accordion title="Can I download templates on Mobile?" >
            No you can not. The downloading of templates is only supported on computers.             </Accordion>
            <Accordion title="Is Customer Support Available? " >
            Yes, you can contact the team at - support@aaaacccellerator.comm</Accordion>
        </div>
    );
}