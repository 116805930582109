import React from "react";
import Image from "next/image";
import Link from "next/link";
import { useQuery } from "react-query";
import { getUserCreditBalance } from "~/api/users";
import { useSession, signOut } from "next-auth/react";
import { useRouter } from "next/router";



export default function AppBar({
  children,
  isDark = false,
  isOnCurrentPage = true,
  isProductsPage = false,
}: {
  children?: React.ReactNode;
  isDark?: boolean;
  isOnCurrentPage?: boolean;
  isProductsPage?: boolean;
}) {
  const { status, data: sessionData } = useSession();
  const isLoggedIn = status === "authenticated";

  const handleLogout = async () => {
    await signOut();
  };

  const {
    data,
    isLoading: isCreditsLoading,
    isError,
  } = useQuery("get-credit", getUserCreditBalance, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
    enabled: isLoggedIn,
  });
  const isLoading = status === "loading" || isCreditsLoading;
  const userPermissions = sessionData?.user?.permissions;

  const isPermissionsGreaterThan4 =
    typeof userPermissions === "number" ? userPermissions > 4 : false;

  

  return (
    <div
      className={` z-10 p-2 font-medium m-5 flex h-20 justify-between items-center ${
        !isDark ? "text-white" : "text-black"
      }`}
    >
      <Link href="/">
        <Image
          src={
            isDark
              ? "/images/footer/Automation Station Logo b.svg"
              : "/images/landing/Automation Station Logo.svg"
          }
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-auto"
          alt="Logo"
        />
      </Link>
      <div className="nav-mid">
      </div>
      {children}
      {isLoggedIn ? (

       <div className="flex gap-x-8 justify-center items-center">
        {isProductsPage && (
            <Link href="/main">
              <button
                className={`${
                  isLoading &&
                  "animate-pulse bg-gray-100 py-4 px-[1.375rem] rounded-xl w-[8rem] "
                } ${
                  isOnCurrentPage && !isLoading
                    ? "bg-[#443BBC]  rounded-xl text-white py-3 px-[1.375rem] "
                    : ""
                }`}
              >
                {isLoading ? <>&nbsp;</> : "Back"}
              </button>
            </Link>
          )}
          <button
            onClick={handleLogout}
            className={`${
              isLoading &&
              "animate-pulse bg-gray-100 py-4 px-[1.375rem] rounded-xl w-[8rem]"
            } ${
              isOnCurrentPage && !isLoading
                ? "bg-[#443BBC] hover:bg-[#6348D6] rounded-xl text-white py-3 px-[1.375rem]"
                : ""
            }`}
          >
                  {isLoading ? <>&nbsp;</> : "Log Out"}
          </button>
          {isPermissionsGreaterThan4 ? (
            <div className="flex gap-x-1">
              <Link href="/account" className="">
                <img src="/images/main/usericon.svg" alt="User icon" />
              </Link>
            </div>
          ) : null}
        </div>

        
        // <div className="flex gap-x-8 text-gray-950">
        //   {/* Action buttons */}
        //   <button className="flex justify-center py-4 px-6 rounded-xl items-center gap-x-1 bg-[#F1F5FB]">
        //     <span className="text-[#475569]">Credits:</span>
        //     <span className="text-[#020618] font-bold">
        //       {(!isCreditsLoading && data && data.credit_balance) || (
        //         <div className="animate-pulse w-16 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        //       )}
        //     </span>
        //   </button>

          // {isPermissionsGreaterThan4 ? (
          //   <div className="flex gap-x-1">
          //     <Link href="/account" className="">
          //       <img src="/images/main/usericon.svg" alt="User icon" />
          //     </Link>
          //   </div>
          // ) : null}
        // </div>
      ) : (
        <div className="flex gap-x-8 justify-center items-center">
          {/* Action buttons */}
          <Link
  className={`${
    isLoading &&
    "animate-pulse bg-gray-100  py-4 px-[1.375rem] rounded-xl w-[8rem]"
  } ${
    (isOnCurrentPage && !isLoading) ? 
  
    "bg-[#443BBC] hover:bg-[#6348D6] rounded-xl text-white py-3 px-[1.375rem]"
    : "" 
  }`}
  href="/login"
>
  {isLoading ? <>&nbsp;</> : "Log In"}
</Link>
<Link
  className={` ${
    isLoading &&
    "animate-pulse bg-gray-100 py-4 px-[1.375rem] rounded-xl w-[8rem]"
  } ${
    (isOnCurrentPage && !isLoading) ? "bg-gray-200" : "" 
  } bg-[#FFFFFF1F] ${
    isDark ? "text-black" : "text-white"
  } rounded-xl gap-2 py-3 px-[1.375rem] items-center`}
  href="signup"
>
  {isLoading ? <>&nbsp;</> : "Create Account"}
</Link>
        </div>
      )}
    </div>
  );
}
