import Head from "next/head";
import TopBanner from "../components/Landing/TopBanner";
import AboutUs from "~/components/Landing/AboutUs";
import Explore from "~/components/Landing/Explore";
import FAQs from "~/components/Landing/FAQs";
import Footer from "~/components/Footer";
import Landing from "~/layouts/Landing";
import OverlayImages from "~/components/OverlayImages";

const MainPage = () => {
  return (
    <>
      <Head>
        <title>Automation Station</title>
      </Head>
      <Landing>
        <div className="landing-section">
          <TopBanner />
          <OverlayImages />
          <div className="landing-section about-us">
            <AboutUs />
          </div>
        </div>

        <section className="desc">
          <div className="landing-section" id="explore">
            <Explore />
          </div>

          <div className="landing-section">
            <FAQs />
          </div>
        </section>
        <Footer />
      </Landing>
    </>
  );
};

export default MainPage;
