import React, { useState } from "react";

export interface IAccordion {
    title: string;
    children: React.ReactNode;
}
export default function Accordion(props: IAccordion) {
    const [isExpanded, setIsExpanded] = useState(false);
    const { title, children } = props;
    return (
        <div >
            <div
                className="rounded-t-lg rounded-b-lg border border-neutral-200 mb-1 ">
                <h2 className="mb-0" id="headingOne">
                    <button
                        className={`group relative flex w-full h-[86px] items-center rounded-t-lg rounded-b-lg border-0 ${!isExpanded && "bg-[#F8FAFD]"} font-semibold px-5 py-4 text-left text-base text-[#020618] transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  ${isExpanded && "text-primary"} ${isExpanded && "[box-shadow:inset_0_-1px_0_rgba(229,231,235)]"}`}
                        type="button"
                        data-te-collapse-init
                        data-te-target="#collapseOne"
                        aria-expanded="true"
                        onClick={() => setIsExpanded(!isExpanded)}
                        aria-controls="collapseOne">
                        {title}
                        <span
                            className={`ml-auto h-5 w-5 shrink-0  fill-[#336dec] transition-transform duration-200 ease-in-out ${!isExpanded ? "rotate-0" : "rotate-[-180deg]"} ${!isExpanded && "fill-[#212529]"} motion-reduce:transition-none `}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </span>
                    </button>
                </h2>
                <div
                    id="collapseOne"
                    className={!isExpanded ? "hidden" : ""}
                    data-te-collapse-item
                    data-te-collapse-show
                    aria-labelledby="headingOne"
                    data-te-parent="#accordionExample">
                    <div className="px-5 py-4">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}