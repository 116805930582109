import { useCallback, useEffect, useRef } from "react";

export const API_BASE_PATH = "/rest/api";

export const useDebounce = (callback: Function, timeout = 1000) => {
    const timeoutIDRef = useRef<NodeJS.Timeout>();
    const callbackRef = useRef<Function>(callback);
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);
    const debouncedFunction = useCallback((...args: any[]) => {
        if (timeoutIDRef.current) {

            clearInterval(timeoutIDRef.current);
        }
        timeoutIDRef.current = setTimeout(() => callbackRef.current(...args), timeout);
    }, [timeout]);
    return debouncedFunction;
};

const hashCode = function (name: string) {
    var hash = 0, i, chr, len;
    if (name.length === 0) return hash;
    for (i = 0, len = name.length; i < len; i++) {
        chr = name.charCodeAt(i);
        hash = ((hash << 2) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};
export const randomRating = (name: string) => {
    const hash = hashCode(name);
    console.log(`Hash = ${hash} => ${Math.floor(hash * 3) + 3}`);
    return Math.floor((hash / 100 ** 10) * 3) + 4;
};