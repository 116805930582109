import Link from "next/link";
import Image from "next/image";
import FooterImage from "../../public/images/footer/footer-background.svg";
import LogoImage from "../../public/images/footer/Automation Station Logo b.svg";

export default function Footer() {
    return (
        <div className="footer-section flex m-[var(--app-bar-margin)] p-12 my-8 bg-[#F8FAFD] rounded-[1.375rem] justify-between  items-stretch relative">
            <div className="flex flex-col justify-between items-start ">
                    <Image
                        src={LogoImage}
                        alt="logo"
                        className="z-10"
                    />
                {/* <div className="footer-links">
                    <Link href="#">
                        Privacy Policy
                    </Link>

                    <Link href="#">
                        Cookie Policy
                    </Link>

                    <Link href="#">
                        Terms of Use
                    </Link>
                </div> */}
            </div>
            <div className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 flex items-center overflow-hidden">
                <Image
                    src={FooterImage}
                    alt="footer"
                    height={200}
                />

            </div>
            <div className="flex gap-x-20 z-10">
                {/* <div className="flex flex-col gap-y-8">
                    <span className="leading-5 text-xl text-[#CBD5E1] font-semibold">Products</span>
                    <div className="flex flex-col gap-y-6 font-medium leading-5 text-[#020618]">
                        <Link href="#">Marketplace</Link>
                        <Link href="#">How to Use</Link>
                        <Link href="#">Developer Hub</Link>
                    </div>

                </div> */}
                <div className="flex flex-col gap-y-8 z-10">
                    <span className="leading-5 text-xl text-[#CBD5E1] font-semibold">Contact</span>
                    <div className="flex flex-col gap-y-6 font-medium leading-5 text-[#020618]">
                        {/* <Link href="#">+64 012 345 67 89</Link>
                        <Link href="#">morningsideai@mail.com</Link>
                        <div className="w-40">115 Moir Street, Wellington Central,New Zealand</div> */}
                        <Link href="mailto:support@aaaaccelerator.com" >
                        support@aaaacccellerator.com
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}