import Image from "next/image";
import AppBar from "~/layouts/AppBar";
import Link from "next/link";

export default function TopBanner() {
    return (
        <div className="top-banner">
                            <img src="/images/landing/bg.png" className="landing-bg"  alt="Top Banner" />
                 <AppBar 
                 isOnCurrentPage={false}
                 />     
                        <div className="main-title">
                            <h1 >
                            The Cost Effective Solution For Automating A Business
                            </h1>
                            <Link className="main-button hover:bg-gray-100" href='/main'>
                                <span>Marketplace</span> <img src="/images/landing/RightArrow.svg" width="24" height="24" alt="Right Arrow" />
                            </Link>
                        </div>
                    {/* </div> */}
            </div>
    );
}