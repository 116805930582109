import React from "react";
import Image from "next/image";

export default function OverlayImages() {
    return (  
<div className="absolute w-full">
<div className="relative w-full">
<img 
className="vector-1 w-auto h-auto "   
src="/images/landing/vector1.png" 
alt='vector' />

<img  
className="vector-2 w-auto h-auto"   
src="/images/landing/vector2.png" 
alt='vector' />
<img  
className="vector-3 w-auto h-auto "   
src="/images/landing/vector3.png" 
alt='vector' />
 <img  
 className="vector-4 w-auto h-auto"   
 src="/images/landing/Vector4.png" 
 alt='vector' /> 
 </div>
 </div>
  

    );
}