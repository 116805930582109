import { QueryFunctionContext } from "react-query";
import { Creators, IReviews, ITemplate, IAddTemplate } from "~/types/API";
import { authFetch } from "~/utils/Auth";
import { API_BASE_PATH } from "~/utils/general";

export interface IGetTemplates {
    query?: string
}

export const createTemplate = async ( templateData: IAddTemplate) => {
    const apiEndpoint = `${API_BASE_PATH}/v1/templates/create`;

    const response = await authFetch(apiEndpoint, {
        body: JSON.stringify(templateData),
        headers: new Headers({ "content-type": "application/json" }),
        method: "POST",
    });

    if (response.ok) {
        const data = await response.json();
        return data.payload;
    } else {
        throw new Error("Error while creating the template");
    }
};


export const addTemplateImage = async (templateId: string, image: File) => {
    const apiEndpoint = `${API_BASE_PATH}/v1/templates/${templateId}/images`;

    const response = await authFetch(apiEndpoint, {
        body: JSON.stringify({
            file_name: image.name,
            file_type: image.type.split("/")[1]
        }),
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
    });
    if (response.ok) {
        const data = await response.json();
        return data.payload;
    } else {
        throw new Error("Error while adding the image");
    }
};


export const updateTemplate = async (templateId: string, templateData: Partial<ITemplate>) => {
    const apiEndpoint = `${API_BASE_PATH}/v1/templates/update/${templateId}`;
  
    const response = await authFetch(apiEndpoint, {
      body: JSON.stringify(templateData),
      headers: new Headers({ "content-type": "application/json" }),
      method: "PUT",
    });
  
    if (response.ok) {
      const data = await response.json();
      return data.payload;
    } else {
      throw new Error("Error while updating the template");
    }
  };
  
  


export const getTemplates = async (context: QueryFunctionContext) => {
    const { queryKey } = context;
    const [, payload] = queryKey;
    const apiEndpoint = `${API_BASE_PATH}/v1/templates/search`;
    const response = await authFetch(apiEndpoint, {
        body: JSON.stringify(payload),
        headers: new Headers({ "content-type": "application/json" }),
        method: "POST",
    });
    if (response.ok) {
        const data = await response.json();
        return data.payload;
    } else {
        throw new Error("Error while fetching the data");
    }
};


export const getCreators = async (context: QueryFunctionContext) => {
    const apiEndpoint = `${API_BASE_PATH}/v1/templates/search/creator-count`;
    const response = await authFetch(apiEndpoint, {
        body: JSON.stringify({}),
        headers: new Headers({ "content-type": "application/json" }),
        method: "POST",
    });
    if (response.ok) {
        const data = await response.json() as Creators;
        return data.payload;
    } else {
        throw new Error("Error while fetching the data");
    }
};


export const getTemplateDetails = async (context: QueryFunctionContext): Promise<ITemplate> => {
    const { queryKey } = context;
    const [, productID] = queryKey;
    const apiEndpoint = `${API_BASE_PATH}/v1/templates/${productID}`;
    const response = await authFetch(apiEndpoint);
    if (response.ok) {
        const data = await response.json();
        return data.payload;
    } else {
        throw new Error("Error while fetching the data");
    }
};


export const getTemplateReviews = async (context: QueryFunctionContext): Promise<IReviews> => {
    const { queryKey } = context;
    const [, productID] = queryKey;
    const apiEndpoint = `${API_BASE_PATH}/v1/templates/${productID}/reviews`;
    const response = await authFetch(apiEndpoint);
    const data = await response.json();
    if (response.ok) {
        return data.payload;
    } else {
        throw new Error("Error while fetching the data", data);
    }
};




export const purchaseTemplate = async (productID: string) => {
    const apiEndpoint = `${API_BASE_PATH}/v1/templates/${productID}/purchase`;
    const response = await authFetch(apiEndpoint, {
        method: "POST",
        body: JSON.stringify({}),
        headers: new Headers({ "content-type": "application/json" }),
    });
    const data = await response.json();
    if (response.ok) {
        return data.payload;
    } else {
        if (data.payload) {
            throw new Error(data.payload);

        } else {
            throw new Error("Error while fetching the data");
        }
    }
};

export const getTemplateFile = async (templateId: string): Promise<string> => {
    const apiEndpoint = `${API_BASE_PATH}/v1/templates/${templateId}/file`;
    const response = await authFetch(apiEndpoint);

    if (response.ok) {
        const data = await response.json();
        // Assuming the pre-signed URL is returned in the payload
        return data.payload.presigned_url;
    } else {
        throw new Error("Error while fetching the template file");
    }
};